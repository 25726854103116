import React, { useState, useEffect } from "react";

import Modal from "../UI-Comps/Modal";
import { axiosApiGet, axiosApiPut } from "../../network/api/RequestsApi";
import validator from "validator";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const ChangePassword = ({ setIsShowChangePassword, isShowChangePassword }) => {
  const textDocument = useSelector((state) => state.textDocument || {});
  const envDefaults = useSelector((state) => state.envDefaults);

  const navigate = useNavigate();

  //User message
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [errorValidEmail, setErrorValidEmail] = useState("");

  const [validit, setValidit] = useState();


  useEffect(() => {

    async function passwordValidity() {
      const res = await axiosApiGet(`/${envDefaults.public}/${envDefaults.passwordValidityRoute}`);
      if (res?.payload)
        setValidit(res?.payload)
    }
    passwordValidity();
  }, [envDefaults.passwordValidityRoute, envDefaults.public]);


  //Valid NewPassword
  function IsValidNewPassword(value) {
    if (
      validator.isStrongPassword(value,
        {
          minLength: validit?.[4]?.minimum ?? 1,
          minLowercase: validit?.[1]?.lowercase ?? 0,
          minUppercase: validit?.[2]?.uppercase ?? 0,
          minNumbers: validit?.[0]?.digit ?? 0,
          minSymbols: validit?.[3]?.special ?? 0,
        })) {
      setErrorValidEmail();
      setNewPassword(value);
    } else {
  /* eslint-disable-next-line */
      setErrorValidEmail(textDocument.ErrorNewPasswordChange.replace('${minimum}', validit?.[4]?.minimum ?? 1));

      setNewPassword();
    }

  }

  async function sendingUpdatePassword() {

    if (newPassword === oldPassword) {
      setErrorValidEmail(textDocument.SamePasswords);
      setNewPassword();
    }
    else {

      const res = await axiosApiPut(`/${envDefaults.usersRoute}/${envDefaults.meRoute}/${envDefaults.passwordRoute}`, { new_password: newPassword, old_password: oldPassword });
      if (res?.status === "ok") {
        setErrorValidEmail(textDocument.ChangePasswordSuccessfuly);
        setTimeout(() => {
          setIsShowChangePassword(false)
          setErrorValidEmail("");
          setOldPassword("");
          setNewPassword("");
        }, 700);
        navigate("/Login");
      } else if (res?.error_message) {
        if (res?.error_message === "old_password does not exists")
          setErrorValidEmail(textDocument.OldPasswordNotExists)
      }
      else if (res === false)
        setErrorValidEmail(textDocument.ServerProblem)
    }

  }


  return (
    <>
      <Modal
        show={isShowChangePassword}
        onCancel={() => setIsShowChangePassword(false)}
        onSubmit={(e) => {
          e.preventDefault();
          sendingUpdatePassword(e.target.value);
        }}
        header={ textDocument.ChangePassword}
        modalButtonLeft={(oldPassword && newPassword && textDocument?.ChangePasswordSuccessfuly!==errorValidEmail) && textDocument.modalButtonLeft}
        modalButtonRight={textDocument?.ChangePasswordSuccessfuly!==errorValidEmail &&textDocument.modalButtonRight}
      >
        <div className="change-password">
          <input type="text" id="username" autoComplete="username" name="username" hidden></input>

          <label htmlFor="old-password">{textDocument.OldPassword}</label>
          <input
            id="old-password"
            autoComplete="old-golf-caesarea"

            type="password"
            name="old-password"
            onChange={(e) => {
              setOldPassword(e.target.value);
            }}
          />

          <label htmlFor="new-password">{textDocument.NewPassword}</label>
          <input
            id="new-password"
            autoComplete="new-golf-caesarea"

            type="password"
            name="new-password"
            onChange={(e) => {
              setNewPassword(e.target.value);
              IsValidNewPassword(e.target.value);
            }}
          />

          <br />
          <label>{errorValidEmail}</label>
        </div>
      </Modal>
    </>
  );
};



export default ChangePassword;
