import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import Backdrop from "./Backdrop";
import { useSelector } from "react-redux";

import "./Modal.css";

// תצוגת פופאפ

const ModalOverlay = (props) => {
  const nodeRef = useRef(null);
  const isMobile = useSelector((state) => state.isMobile || {});


  const content = (
    <div
      ref={nodeRef}
      style={{
        width: `${props.width ? props.width : "30%"}`,
        left: `${props.left ? props.left : "35%"}`,
        right: `${props.right ? props.right : "35%"}`,
          marginTop: isMobile?.marginLeft ?"10%":'3%'
        
      }}
      className={`modal`}
    >
      {/* כותרת הפופ אפ */}
      <header className={`modal__header ${props?.headerClass}`}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <h2 >{props?.header ?? "Tee Time"}</h2>
          <span
            // className="x_close"
            className={`x_close x_close_${props?.textDocument.language !== "Hebrew" ? 'RightToLeft' : 'LeftToRight'
              }`}
            onClick={props.onCancel}>
            x
          </span>
        </div>
      </header>

      {/* אם זה פופאפ שיש בתוכו שדות של טופס אז תהיה אפשרות גם לשלוח פונקציה של אישור בפרופס  */}
      <div id="modal-form"

        // אם הפופ אפ הוא טופס, ניתן לשלוח פונקציה שתגיד מה לעשות באישורו
        onSubmit={props.onSubmit ? props.onSubmit : (e) => e.preventDefault()}
      >
        {/* תוכן הפופאפ */}
        <div className={`modal__content ${props.contentClass}`}>
          {props.children}
        </div>

        <hr />
        {/* תחתית הפופאפ - כפתורים */}
        <div className={`modal__footer`}>
          {/*-  אם יש עוד כפתור - הכפתור הימני הוא לביטול והכפתור השמאלי יהיה לאישור*/}
          {props.modalButtonLeft && (
            <button
              type={props.onSubmit ? "" : "button"}
              // className="modal_button button_left"
              className={`modal_button ${props?.textDocument.language !== "Hebrew" ? 'button_left' : 'button_right'
                }`}
              onClick={props.modalButtonRight ? props.onSubmit : props.onCancel}
              variant="contained"
              // size="large"
              sx={{
                backgroundColor: "#228c3d",
                color: "white",
                border: '1px solid #228c3d',
                "&:hover": {
                  backgroundColor: "white",
                  color: "#228c3d",

                }
              }}
            >
              {props.modalButtonLeft}
            </button>

          )}

          {/* אם יש עוד כפתור */}
          {props.modalButtonRight && (
            <button
              sx={{
                backgroundColor: "#228c3d",
                color: "white",
                border: '1px solid #228c3d',
                "&:hover": {
                  backgroundColor: "white",
                  color: "#228c3d",

                }
              }}
              type="button"
              // className="modal_button button_right"
              className={`modal_button ${props?.textDocument.language !== "Hebrew" ? 'button_right' : 'button_left'
                }`}
              onClick={props.onCancel}
            >
              {" "}
              {props.modalButtonRight}{" "}
            </button>
          )}
        </div>
      </div>


      {/* <form></form> */}
    </div >
  );
  return content

};

const Modal = (props) => {
  const nodeRef = useRef(null);

  const textDocument = useSelector((state) => state.textDocument || {});

  return (
    <>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="modal"
        nodeRef={nodeRef}
      >
        <ModalOverlay {...props} textDocument={textDocument} />

      </CSSTransition>
    </>
  );
};

export default Modal;
