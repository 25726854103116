import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#DBE8E1',
  color: theme.palette.common.black,
  fontSize: 14,
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  textAlign: 'center',
  padding: '16px 6px',
  [theme.breakpoints.down('sm')]: {
    fontSize: 8,
    padding: '8px 4px',
    lineHeight: 1.1
  }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#ffffff'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#f5f8f6'
  },
  '& .MuiTableCell-root': {
    textAlign: 'center',
    padding: '12px 6px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
      padding: '8px 4px',
      lineHeight: 1.1
    }
  },
  '&:hover': {
    backgroundColor: '#e8f4fd',
  }
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: 8,
    padding: '4px 8px',
    minWidth: 'auto'
  }
}));
export const TableContainerStyles = (textDocument) => ({
  maxHeight: 420,
  // direction: textDocument.language !== "Hebrew" ? 'ltr' : 'rtl',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#228c3d',
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#1a6b2e'
  },
  '& .MuiTable-root': {
    '@media (max-width: 600px)': {
      '& .MuiTableCell-root': {
        padding: '8px 4px',
        fontSize: '12px'
      },
      '& .MuiButton-root': {
        fontSize: '12px',
        padding: '6px 12px'
      }
    }
  }
});
export const TableStyles = {
  minWidth: {
    xs: 300,
    sm: 650
  }
};

export const CameraIconStyles = {
  cursor: 'pointer',
  color: '#228c3d',
  '&:hover': {
    color: '#6db07f'
  }
};

export const CameraCellStyles = {
  width: '25px',
  minWidth: '25px',
  
};

export const NameCellStyles = {
  maxWidth: {
    xs: '130px',  // increased from 80px
    sm: '210px'   // increased from 150px
  },
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};
export const ApprovedCellStyles = {
  width: '30px',
  minWidth: '30px'
};
export const RemarkCellStyles = {
  cursor: 'pointer',
  color: '#1976d2',
  '&:hover': {
    fontWeight: 'bold'
  },
  whiteSpace: 'normal',
  overflow: 'hidden',
  maxWidth: '180px'
};

