import moment from "moment";
import { useEffect, useState } from "react";

import GameOrderConfirmation from "./GameOrderConfirmation";
import { useNavigate, useLocation } from "react-router-dom";
import { axiosApiDelete, axiosApiPost, axiosApiPut } from "../../network/api/RequestsApi";
import { DayInWeek } from '../../Logic/dayInWeek'
import { setTextPopup } from "../../redux/login/LoginAction";
import { useDispatch, useSelector } from "react-redux";

const GameOrderConfirmationContainer = () => {

  const navigate = useNavigate();


  const { state } = useLocation();
  const order = state && state.order ? state.order : null;
  const ordersList = state?.ordersList ?? false;

  const [secondsLeft, setSecondsLeft] = useState(3 * 60);
  const minutes = Math.floor(secondsLeft / 60).toString().padStart(2, '0');
  const seconds = (secondsLeft % 60).toString().padStart(2, '0');
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const date = params.get('selectedDate');
  const time = params.get('selectedTime');
  const wcCode = params.get('wcCode');

  useEffect(() => {
    if (!date || !time) {
      navigate('/error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const envDefaults = useSelector((state) => state.envDefaults);
  const player_name = useSelector((state) => state?.detail?.player_name || "שחקן");
  const player_latin_name = useSelector((state) => state?.detail?.player_latin_name);

  const user_code = useSelector((state) => state?.detail?.user_code || 1);

  const textDocument = useSelector((state) => state.textDocument || {});
  const [showTimerPopup, setShowTimerPopup] = useState(false);
  const user_type = useSelector((state) => state.detail?.user_type);
  const dispatch = useDispatch();
  const [showWrongFriendNumber, setShowWrongFriendNumber] = useState(false);
  const [isRemoveFriend, setIsRemoveFriend] = useState([]);
  const [newArray, setNewArray] = useState();


  const [newOrder, setNewOrder] = useState({
    event_date: moment(date, "DD.MM.YY").format("YYYY-MM-DD"),
    start_time: time,
    wc_code: order?.wc_code ? order.wc_code : wcCode === "par3" ? "2" : "1",
    players:
      order ?
        (user_type !== 2 && !ordersList) ? [...order.players, {
          player_name:
            isNaN(player_name) ?
              player_latin_name ?
                textDocument.language !== "Hebrew" ? player_name : player_latin_name
                :

                player_name
              : `${textDocument.player_name}${player_name}`, user_code: user_code, car: false, stick: false
        }] : [...order.players]
        :
        user_type !== 2 ?
          [
            {
              player_name:
                isNaN(player_name) ?
                  player_latin_name ?
                    textDocument.language !== "Hebrew" ? player_name : player_latin_name
                    :

                    player_name
                  : `${textDocument.player_name}${player_name}`, user_code: user_code, car: false, stick: false
            },

          ] :
          []

  });
  useEffect(() => {
    setIsRemoveFriend(order ?
      [...order.players]
      :
      user_type !== 2 ?
        [
          {
            player_name:


              isNaN(player_name) ?
                player_latin_name ?
                  textDocument.language !== "Hebrew" ? player_name : player_latin_name
                  :

                  player_name
                : `${textDocument.player_name}${player_name}`, user_code: user_code, car: false, stick: false
          },

        ] :
        [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const endOrder = async () => {
    const newArray = {
      ...newOrder,
      players: newOrder.players.filter(player => player.new_friend === true)
    };
    const selfArray = {
      ...newOrder,
      players: newOrder.players.filter(player => player.user_code === user_code.toString() ||
        player.user_code.toString() === user_code.toString() || player.user_code === user_code)
    };
    let requestData = newArray;

    if (newArray.players.length > 0) {
      requestData = {
        ...newArray,
        old_order: true,
        self: selfArray ? true : false
      };
    }

    let resAxiosApiPut;
    let resAxiosApiPost;
    if (newArray.players.length > 0 || selfArray.players.length > 0) {

      resAxiosApiPost =
        await axiosApiPost(`/${envDefaults?.gameOrderRoute}`,
          (order && user_type !== 2 ? requestData :
            selfArray.players.length > 0 || user_type !== 2 ||
              (order && user_type === 2)
              ?
              await creatObgectForApi(newOrder) : newOrder))

    }
    if (resAxiosApiPost === "false")
      dispatch(setTextPopup(textDocument.ServerProblem))

    if (resAxiosApiPost?.status === "ok" && !order)
      dispatch(setTextPopup(textDocument?.OrderSuccessfully));
    else {
      errorMessage(resAxiosApiPost?.error_message)
    }
    if (order) {
      if (resAxiosApiPost?.status === "ok" || newArray.players.length <= 0) {
        resAxiosApiPut = await axiosApiPut(`/${envDefaults?.gameOrderRoute}`, newOrder)
        if (resAxiosApiPut?.status === "ok") {

          dispatch(setTextPopup(textDocument?.OrderSuccessfully));
          setShowTimerPopup(true);
        } else {
          errorMessage(resAxiosApiPut?.error_message)
        }
      } else {
        errorMessage(resAxiosApiPost?.error_message)
      }
    }

    setShowTimerPopup(true);
  };

  const errorMessage = (error_message) => {

    const matchResult = error_message?.match(/The user code: (\d+)/);
    dispatch(setTextPopup(matchResult ? `${textDocument.UserCode} : ${matchResult[1]} ${textDocument.AlreadyRegistered}`
      :
      textDocument[error_message] ?? textDocument?.ServerProblem));
  };


  const goBackToGameTime = () => {
    if (ordersList)
      navigate('/orderList');
    else if (!ordersList) {

      navigate(`/gameOrder/gameTime?selectedDate=${date}&selectedTime=${time}&wcCode=${wcCode}`);

      // const dateForNavigate = new Date().toLocaleDateString('en-GB').split('/').map((d, i) => i === 2 ? d.slice(-2) : d).join('.');

      // navigate(`/gameOrder/gameTime?selectedDate=${dateForNavigate??date}&wcCode=${wcCode}`);

    }
    // navigate(`/gameOrder/gameTime?selectedDate=${date}&wcCode=${wcCode}`);
  };

  async function creatObgectForApi(newOrder) {

    let indexArray;
    indexArray = newOrder ? newOrder : order

    const index = indexArray?.players.findIndex(
      player => player.user_code === user_code.toString() || player.user_code.toString() === user_code.toString())
    const filteredArray = order && newOrder?.players.filter(obj => !order?.players.some(o => o.user_code === obj.user_code));

    let selfOrfFilteredArray;
    selfOrfFilteredArray = order ? filteredArray : newOrder.players

    const selfArray = {
      players: selfOrfFilteredArray.filter(player => player.user_code === user_code.toString() || player.user_code.toString() === user_code.toString())
    };
    const playersNoOrder = {
      // eslint-disable-next-line
      players: newOrder.players.filter(player => player.user_code != user_code.toString() || player.user_code != user_code)
    };
    let newOrderForApi = {
      event_date: newOrder?.event_date,
      start_time: newOrder.start_time,
      wc_code: newOrder.wc_code,
      self: selfArray.players.length > 0 ? true : false,

      car: selfArray.players.length > 0 ? newOrder.players[index].car : false,
      stick: selfArray.players.length > 0 && (newOrder.players[index].stick !== "" && newOrder.players[index].stick !== undefined && newOrder.players[index].stick !== false) ?
        true
        : false,
    };
    if (newOrderForApi.stick === true) {
      newOrderForApi.stick_gender = newOrder.players[index].stick_gender
      newOrderForApi.stick_hand = newOrder.players[index].stick_hand
      newOrderForApi.stick_kind = newOrder.players[index].stick_kind
    }
    if (order) {
      newOrderForApi.old_order = true
      const indexFilteredArray = filteredArray?.findIndex(
        player => player.user_code === user_code.toString() || player.user_code.toString() === user_code.toString())

      if (indexFilteredArray !== -1) { filteredArray.splice(indexFilteredArray, 1); }
      newOrderForApi.players = filteredArray
    } else {
      newOrderForApi.players = playersNoOrder.players

    }


    return newOrderForApi;
  }


  useEffect(() => {
    if (secondsLeft > 0) {
      const timerId = setTimeout(() => setSecondsLeft(secondsLeft - 1), 1000);
      return () => clearTimeout(timerId);
    } else {
      dispatch(setTextPopup(textDocument.TimeUp));
      setShowTimerPopup(true);
    }
  }, [secondsLeft, dispatch, textDocument.TimeUp]);

  const removeFriend = async (new_friend, user_code, event_date, wc_code, start_time, player_name) => {
    let res;
    if (!new_friend) {
      res = await axiosApiDelete(`/${envDefaults.gameOrderRoute}`, { user_code, event_date, wc_code, start_time })
    }
    if (res?.payload || new_friend) {
      const newArray = {
        ...newOrder,
        players: newOrder.players.filter(player => player.user_code !== user_code)
      };

      dispatch(setTextPopup(player_name))
      // setIsRemoveFriend(true)
      setNewArray(newArray)
      if (user_type === 2) {
        setNewOrder(newArray);
        dispatch(setTextPopup(textDocument["The player has been successfully removed"]))
      }
    }
    else {
      dispatch(setTextPopup(textDocument.FailedDeleteFriend))
    }
    if (user_type !== 2) setShowWrongFriendNumber(true);
    // else {
    //   setNewOrder(newArray);
    // }
  }

  return (
    <GameOrderConfirmation
      minutes={minutes}
      seconds={seconds}
      goBackToGameTime={goBackToGameTime}
      endOrder={endOrder}
      setNewOrder={setNewOrder}
      newOrder={newOrder}
      day={DayInWeek(date, textDocument)}
      wcCode={wcCode}
      showTimerPopup={showTimerPopup}
      setShowTimerPopup={setShowTimerPopup}
      removeFriend={removeFriend}
      showWrongFriendNumber={showWrongFriendNumber}
      setShowWrongFriendNumber={setShowWrongFriendNumber}
      isRemoveFriend={isRemoveFriend}
      ordersList={ordersList}
      newArray={newArray}
      setNewArray={setNewArray}
    />
  );
};

export default GameOrderConfirmationContainer;
