import { CONNECT, ADD_ITEM, TEXT_POPUP, DELETE_ITEM, DIRECTION, SET_TEXT_DOC,SET_ENV_DEFAULTS, SET_MY_ORDER_LIST, USER_NAME, SET_DETAIL, FROM_DATE, TO_DATE, BLOCK_DAYS, SET_WC_CODE, SET_IS_MOBILE } from "../constants";

const addItem = () => {
  return {
    type: ADD_ITEM,
  };
};
export const setDir = () => ({
  type: DIRECTION,
});
export const setTextDoc = (textDoc) => {
  return {
    type: SET_TEXT_DOC,
    payload: textDoc,
  };
};
export const setIsMobile = (textDoc) => {
  
  return {
    type: SET_IS_MOBILE,
    payload: textDoc,
  };
};
export const setDetail = (detail) => {
  return {
    type: SET_DETAIL,
    payload: detail,
  };
};
export const setArayBlockedDays = (blockedDays) => {
  return {
    type: BLOCK_DAYS,
    payload: blockedDays,
  };
};
export const setFromDate = (FromDate) => {
  return {
    type: FROM_DATE,
    payload: FromDate,
  };
};
export const setToDate = (ToDate) => {
  return {
    type: TO_DATE,
    payload: ToDate,
  };
};
export const setconnect = (connect) => {
  return {
    type: CONNECT,
    payload: connect,
  };
};
export const setTextPopup = (textPopup) => {
  return {
    type: TEXT_POPUP,
    payload: textPopup,
  };
};
export const setUserName = (userName) => {
  return {
    type: USER_NAME,
    payload: userName,
  };
};
export const setEnvDefaults = (envDefaults) => {
  return {
    type: SET_ENV_DEFAULTS,
    payload: envDefaults,
  };
};
export const setMyOrdersList = (myOrdersList) => {
  return {
    type: SET_MY_ORDER_LIST,
    payload: myOrdersList,
  };
};
export const setWcCodeRedux = (wc_code_redux) => {
  return {
    type: SET_WC_CODE,
    payload: wc_code_redux,
  };
};
const deleteItem = () => {
  return {
    type: DELETE_ITEM,
  };
};

export { addItem, deleteItem };
