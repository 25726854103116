

import golpher from "../../images/golpher-icon.png";

import { useSelector } from "react-redux";
import golpher_lub from "../../images/golpher-club.png";
import club_car from "../../images/club_car.png";

import "../../css/golf-rtl.css";
import "../../css/golfstyle.css";
import "../../css/reset.css";
import "reactjs-popup/dist/index.css";
import moment from "moment";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { axiosApiPut } from "../../network/api/RequestsApi";
import Modal from '../UI-Comps/Modal'

const GameTime = (props) => {

    const { gameTimeList, wcCode, index, setIndex, date, day ,hoursList} = props;
    // const elementTime = moment(gameTimeList?.[index]?.time, 'HH:mm');
    // const currentTime = moment();
    // const diffMinutes = elementTime.diff(currentTime, 'minutes');


    const navigate = useNavigate();


    const textDocument = useSelector((state) => state.textDocument || {});
    const envDefaults = useSelector((state) => state.envDefaults);

    const [arrivedList, setArrivedList] = useState([]);
    const [certainVariable, setCertainVariable] = useState(false);

    const [showPopup, setShowPopup] = useState(false);
    const [showPopupMessage, setShowPopupMessage] = useState();
    const formattedDate = moment(date, ["DD.MM.YY", "YYYY-MM-DD"]).format("DD.MM.YY");
    // const isToday = moment(formattedDate, 'DD.MM.YY').isSame(moment(), 'day');
    // const isPast = diffMinutes > 0;
    // const canEdit = isToday && isPast;


    const getIndex = async () => {
        if (index > 0) {
            if (!gameTimeList?.[index - 1]?.message)
                setIndex(index - 1)
            else {
                for (let j = index - 1; (j < index) && gameTimeList?.[j]?.message; j--) {
                    setIndex(j - 1)
                }
            }
            // setIndex(index - 2)
        }
        else {
            setIndex(0)
        }
    };
    const getIndexPlus = async () => {
        if (!gameTimeList?.[index + 1]?.message) {
            if (index + 1 < gameTimeList.length)
                setIndex(index + 1)
            else
                setIndex(gameTimeList.length - 1)

        }
        else {
            for (let j = index + 1; j < gameTimeList.length && gameTimeList?.[j]?.message; j++) {
                setIndex(j + 1)
            }

        }
    };
    const onOrderGameTime = async (time) => {
        debugger
        if (gameTimeList?.[index]?.players)
            navigate(`/gameOrder/gameTime/gameOrderConfirmation?selectedDate=${formattedDate}&selectedTime=${time}&wcCode=${wcCode}`, { state: { order: gameTimeList?.[index] } });
        else
            navigate(`/gameOrder/gameTime/gameOrderConfirmation?selectedDate=${formattedDate}&selectedTime=${time}&wcCode=${wcCode}`);


    };

    const updateArrivedList = (user_code) => {
        if (!user_code) {
            setCertainVariable(!certainVariable)
            const updatedArrivedList = gameTimeList?.[index]?.players.map((player) => {
                if (player.user_code) {
                    return { user_code: player.user_code, arrived: !certainVariable };
                }
                return player;
            });

            setArrivedList(updatedArrivedList);
        }

            else {
                const existingItemIndex = arrivedList.findIndex(item => item.user_code === user_code);
        
                if (existingItemIndex !== -1) {
                    setArrivedList(prevArrivedList => {
                        const updatedList = prevArrivedList.map(item => {
                            if (item.user_code === user_code) {
                                return { ...item, arrived: !item.arrived };
                            }
                            return item;
                        });
                        return updatedList;
                    });
                } else {
                    setArrivedList(prevArrivedList => [...prevArrivedList, { user_code, arrived: true }]);
                }
        }
    };


    const arrived = async () => {
        
        const res = await axiosApiPut(`/${envDefaults.entryRoute}_${envDefaults.permitRoute}`, { event_date: moment(date, "DD.MM.YY").format("yyyy-MM-DD"), wc_code: wcCode === "par18" ? 1 : 2, start_time: gameTimeList?.[index]?.time, players: arrivedList });
        if (res?.status==="ok"){
            setArrivedList([])
            setShowPopupMessage(textDocument?.DataSavedSuccessfully)

        }
        else {
            setShowPopupMessage(textDocument[res?.error_message] ?? textDocument?.ServerProblem)

        }
        setShowPopup(true)

    };

    return (
        <>
            <Modal
                show={showPopup}
                onCancel={(e) => {
                    hoursList()
                    const index = ((moment().hour() - 6)) * 6 + Math.round(moment().minute() / 10);
                    setIndex(index); // Or use the index variable in a function call
                    setCertainVariable(false)
                    setShowPopup(false)

                }}
                onSubmit={(e) => {
                    hoursList()
                    e.preventDefault();
                    setIndex(index + 1)
                    setShowPopup(false)
                }}
                modalButtonLeft={textDocument.NextGame}
                modalButtonRight={textDocument.BackToList}

            >
                <p>{showPopupMessage}</p>
            </Modal>
            <div className="starter_panel affix  d-none d-lg-inline-block">
                <div className="row starter_panel_title">
                    <div className="starter_panel_title_box pull-dir col-xs-3 col-sm-3 col-md-3 col-lg-3 NoPadding">
                        <div className="starter_panel_title_line_1 ">

                            <span id="ufld:ORD_DAY.DUMMY.MAALE:GDWEBSTRTR.1" className="" >{formattedDate}</span><br />
                            <span id="ufld:DAY_NAME.DUMMY.MAALE:GDWEBSTRTR.1" className="" > {day}</span>
                        </div>
                    </div>

                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 box_center NoPadding">
                        <div className="hour_picker">
                            <div className="pull-dir  Padding-opp-dir-5 PaddingTop3"><span id="ufld:PB_PREV.DUMMY.MAALE:GDWEBSTRTR.1"
                                onClick={() => getIndex()}
                                className="btn btn-default-remove btn-sm" alt="יום אחד לאחור" type="button" >
                                <span>
                                    {textDocument.language !== "Hebrew" ? "<" : ">"}
                                </span>
                            </span>
                            </div>
                            <div className="starter_panel_title_hour pull-dir ">

                                <span id="ufld:ORD_TIME.DUMMY.MAALE:GDWEBSTRTR.1" className="" >{gameTimeList?.[index]?.time}</span>
                            </div>
                            <div className="pull-opp-dir Padding-dir-7 PaddingTop3">
                                <span id="ufld:PB_NEXT.DUMMY.MAALE:GDWEBSTRTR.1"
                                    onClick={() => getIndexPlus()}

                                    className="btn btn-default-remove btn-sm"
                                    alt="יום אחד קדימה"
                                    type="button"
                                >
                                    <span>{textDocument.language !== "Hebrew" ? ">" : "<"}</span>

                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="starter_panel_title_box  col-xs-3 col-sm-3 col-md-3 col-lg-3 NoPadding ">
                        <div className="starter_panel_title_line_1 pull-opp-dir">
                            <span id="ufld:COURSE_LBL.DUMMY.MAALE:GDWEBSTRTR.1" className="" >{textDocument.Field}</span><br />
                            <span id="ufld:COURSE_NAME.DUMMY.MAALE:GDWEBSTRTR.1" className="" >{`Pete Dye ${wcCode} Holes`}</span>
                        </div>
                    </div>
                </div>
                <div className="row PartnersTitle NoMargin ">
                    <div className="starter_golfer_box col-xs-2 col-sm-2 col-md-2 col-lg-2">
                        <input type="checkbox"
                         checked={certainVariable ? true : false} 
                         id="ufld:SEL_ALL.DUMMY.MAALE:GDWEBSTRTR.1" 
                         className=""
                         onChange={e => { updateArrivedList() }} />
                    </div>
                    <span id="ufld:TITLE_PARTICIPANT.DUMMY.MAALE:GDWEBSTRTR.1" className="Name col-xs-7 col-sm-7 col-md-7 col-lg-7" >{textDocument.Players}</span>
                    <span id="ufld:TITLE_RMRK.DUMMY.MAALE:GDWEBSTRTR.1" className="Name col-xs-2 col-sm-3 col-md-3 col-lg-3">{textDocument.Remarks}</span>
                </div>
                {gameTimeList?.[index]?.players?.map((obj, i) => (

                    <div id="uent:PLAYER.MAALE:GDWEBSTRTR" className="-uent-" >
                        <div className="row PartnerRow NoMargin -uocc- -uocc-cur-" id="uocc:PLAYER.MAALE:GDWEBSTRTR.1" >
                            <div className="starter_golfer_box col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                <input type="checkbox" id="ufld:PRESENT.PLAYER.MAALE:GDWEBSTRTR.1" className="" onChange={e => { updateArrivedList(obj?.user_code) }}
                                    // checked={certainVariable ? true : false}
                                    checked={
                                        
                                        obj.arrived ||
                                       ( arrivedList.length > 0 && arrivedList?.some(item => item.user_code === obj?.user_code && item.arrived === true))
                                    }
                                />
                            </div>
                            <div className="col-xs-7 col-sm-7 col-md-7 col-lg-7 NoPadding">

                                <div className="starter_orders_golfer_name">
                                    <img src={golpher} alt={golpher} />
                                    <span className="Name" id="ufld:NAME.PLAYER.MAALE:GDWEBSTRTR.1" >

                                        {!obj?.display ?
                                            obj.player_latin_name ?
                                                textDocument.language !== "Hebrew" ? obj.player_name : obj.player_latin_name
                                                :
                                                obj.player_name_display : obj.player_latin_name_display ?
                                                textDocument.language !== "Hebrew" ? obj.player_name_display : obj.player_latin_name_display
                                                :
                                                obj.player_name_display
                                        }
                                    </span>
                                </div>
                                {obj?.stick && <div className="my_orders_golfer_clubs" id="ufld:CLUB_HID.PLAYER.MAALE:GDWEBSTRTR.1" >
                                    <span><img src={golpher_lub} alt={golpher_lub} /></span>
                                    <span className="TextIdent" id="ufld:CLUB.PLAYER.MAALE:GDWEBSTRTR.1" ></span>
                                </div>}
                                {obj?.car && <div className="my_orders_club_car" id="ufld:CAR_HID.PLAYER.MAALE:GDWEBSTRTR.1" >
                                    <img src={club_car} alt={club_car} />
                                </div>}
                            </div>

                            <div className="starter_golfer_rem col-xs-2 col-sm-3 col-md-3 col-lg-3 NoPadding">
                                <span className="Notes" id="ufld:RMRK.PLAYER.MAALE:GDWEBSTRTR.1" ></span>
                            </div>
                        </div>
                    </div>))}

                <div className="my_orders_footer">
                   
                    <div className="pull-dir">
                    {arrivedList.length>0 && arrivedList?.some(item => item.arrived === true) && (
                        <span id="ufld:PB_ACCEPT.DUMMY.MAALE:GDWEBSTRTR.1"
                        onClick={arrived}
                        className="btn btn-success" type="button" >{textDocument.modalButtonLeft}</span>)}
                    </div>

                    {/* {(canEdit || !isToday) && */}
                        <div onClick={() => onOrderGameTime(gameTimeList?.[index]?.time)} className="pull-opp-dir">
                            <span id="ufld:PB_EDIT.DUMMY.MAALE:GDWEBSTRTR.1" className="btn btn-success" type="button">
                                {textDocument.buttonEditlMyOrders}
                            </span>
                        </div>
                    {/* } */}
                    {/* } */}
                </div>
            </div>


        </>
    );
};

export default GameTime;
