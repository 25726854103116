import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import teeTimeLogo from '../../images/tee-time-logo.png'
import HeaderContainer from "./header-container";


import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#1b7030"
    }, link: {
        color: 'white'
    }, linkMenue: {
        color: '#003b36'
    }
}));
function ResponsiveAppBar(props) {

    const classes = useStyles();
    const player_name = useSelector((state) => state.detail.player_name);
    const player_latin_name = useSelector((state) => state.detail.player_latin_name);
    const user_type = useSelector((state) => state.detail?.user_type);
    const isMobile = useSelector((state) => state.isMobile || {});

    const textDocument = useSelector((state) => state.textDocument || {});
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };


    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };



    return (

        <>
            <AppBar position="fixed" className={classes.appBar}>
                <Container maxWidth="xl">
                    <Toolbar sx={{ justifyContent: "space-around" }} disableGutters>


                        {props.Login &&
                            <Typography
                                variant="h7"
                                noWrap
                                // component="a"
                                // href="/main"
                                sx={{
                                    mr: 2,
                                    display: { xs: "flex", md: "none" },
                                    // fontFamily: "monospace",
                                    // fontWeight: 600,
                                    // letterSpacing: ".3rem",
                                    // color: "inherit",
                                    textDecoration: "none",
                                    justifyContent: "flex-end",
                                    className: "header-username",
                                    margin: 0
                                }}
                            >
                                <div className="header-username-responsive" style={{ marginTop: '6px' }}>

                                    {textDocument.language !== "Hebrew" ? `שלום ${(!player_name ? (user_type === 2 && 'מרשל') : player_name)}` : `Hello ${(!player_latin_name ? (!player_name ? (user_type === 2 && 'marshal') : player_name) : player_latin_name)}`}
                                </div>

                                <img className="header-username" style={{ cursor: "pointer", height: '30px' }} onClick={() => window.location.href = "/main"} src={teeTimeLogo} alt={teeTimeLogo}></img>





                            </Typography>}
                        {/* {/* <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/main"
                        sx={{
                            mr: -1,
                            display: { xs: "flex", md: "none" },
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                            justifyContent: "flex-end",
                            height: '40px'
                        }}
                    >
                        <img src={teeTimeLogo} alt={teeTimeLogo}></img>

                    </Typography> */}

                        {props.Login &&
                            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, justifyContent: "flex-end" }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left"
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left"
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}

                                    sx={{
                                        display: { xs: "block", md: "none" }
                                    }}
                                >
                                    <HeaderContainer {...props} Menue={"Menue"} />
                                </Menu>
                            </Box>}
                        {/* <Typography
                        variant="h7"
                        noWrap
                        sx={{
                            justifyContent: "flex-start"
                        }} >
                         {textDocument.language !== "Hebrew" ? (!player_name ? (user_type === 2 && 'מרשל'):player_name) : (!player_latin_name ? (!player_name ? (user_type === 2 && 'marshal'):player_name):player_latin_name)}


                    </Typography> */}

                        <Typography

                            variant="h7"
                            noWrap
                            // component="a"
                            // href="/main"
                            sx={{
                                // mr: -2,
                                // mt:2,
                                display: { xs: "none", md: "flex" },
                                // fontFamily: "monospace",
                                // fontWeight: 700,
                                // letterSpacing: ".3rem",
                                color: "inherit",
                                textDecoration: "none",
                                justifyContent: "flex-start",
                                height: '40px'
                            }}
                        > {props.Login &&
                            <div style={{ marginTop: '8px' }}>

                                {textDocument.language !== "Hebrew" ? `שלום ${(!player_name ? (user_type === 2 && 'מרשל') : player_name)}` : `Hello ${(!player_latin_name ? (!player_name ? (user_type === 2 && 'marshal') : player_name) : player_latin_name)}`
                                }
                            </div>}

                            <img style={{ cursor: "pointer" }} onClick={() => window.location.href = "/main"} src={teeTimeLogo} alt={teeTimeLogo}></img>

                        </Typography>


                        <Box sx={{ width: '90%', flexGrow: 1, display: { xs: "none", md: "flex", flexDirection: 'row-reverse' } }}>
                            <HeaderContainer {...props} />
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <div style={{ marginBottom: isMobile?.marginLeft ? '15%' :"3%"}}></div>
        </>
    );
}
export default ResponsiveAppBar;