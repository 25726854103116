import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Box,
  CircularProgress,
  TableCell,
} from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  StyledTableCell,
  StyledTableRow,
  TableContainerStyles,
  CameraIconStyles,
  NameCellStyles,
  ApprovedCellStyles,
  RemarkCellStyles,
  CameraCellStyles
} from './MarshalTable.styles';

import PreviousOrNext from '../PreviousOrNext';
import PopFriendImages from "./PopMarshal/PopFriendImages";
import Modal from "../UI-Comps/Modal";
import Header from "../Header/header";
import Footer from "../Footer";
import PopAddFriemds from "./PopMarshal/PopAddFriemds";
import { axiosApiGet } from "../../network/api/RequestsApi";

const Marshal = (props) => {
  const {
    date,
    setDate,
    day,
    entriesList,
    handleRefresh,
    setSeqPlayerRemark,
    remark,
    setRemark,
    isOpenEditRemark,
    setIsOpenEditRemark,
    messagesError,
    editRemark,
    isOpenAddFriendModal,
    setIsOpenAddFriendModal,
    selectedFriendsCode,
    setSelectedFriendsCode,
    addFriends,
    isLoading,
    setMessagesError
  } = props;

  const textDocument = useSelector((state) => state.textDocument || {});
  const envDefaults = useSelector((state) => state.envDefaults);
  const isMobile = useSelector((state) => state.isMobile || {});


  const [isOpenFriendImages, setIsOpenFriendImages] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState("");
  const [selectedUserImg, setSelectedUserImg] = useState("");
  const [selectedEnterImg, setSelectedEnterImg] = useState("");
  const [loader, setLoader] = useState([false, false]);

  //open popup Image
  const handleImageClick = async (userName, code, sequence_number) => {

    const res = await axiosApiGet(`/${envDefaults.customerPictureRoute}`, { customer_code: code });
    setLoader(prevLoader => [true, ...prevLoader.slice(1)]);

    if (res?.payload?.picture) {
      let convertUserName = res.payload?.picture?.replace(/^data:image\/\w+;base64,/, '');
      setSelectedUserImg(convertUserName)

    }
    else if (res?.status === "ok" || res?.status === "failed")
      setSelectedUserImg();

    const resEnterImg = await axiosApiGet(`/${envDefaults.enterPictureRoute}`, { sequence_number: sequence_number });
    setLoader(prevLoader => [prevLoader[0], true]);

    if (resEnterImg?.payload) {
      let convertUserName = resEnterImg.payload?.replace(/^data:image\/\w+;base64,/, '');
      setSelectedEnterImg(convertUserName)
    }
    else if (resEnterImg?.status === "ok" || resEnterImg?.status === "failed")
      setSelectedEnterImg();

    setIsOpenFriendImages(true);
    setSelectedUsername(userName);
  };

  const propsModal = {
    left: "30%",
    width: "20%",
    right: "40%"
  };
  const propsModalButton = {
    modalButtonLeft: textDocument.modalButtonLeft,
    modalButtonRight: textDocument.modalButtonRight,
  };
  return (
    <>
      <Header Login={"Login"} Main={"Main"} />

      <div className="entrance-control">
        <Modal
          // {...propsModal}
          show={isOpenAddFriendModal}
          onCancel={() => {
            setSelectedFriendsCode()

            setIsOpenAddFriendModal(false)
            setMessagesError()
          }}
          onSubmit={(e) => {
            e.preventDefault()

            addFriends()
          }}
          modalButtonLeft={selectedFriendsCode && textDocument.modalButtonLeft}
          modalButtonRight={textDocument.modalButtonRight}

          header={textDocument.AddFriend}
        >
          <PopAddFriemds setSelectedFriendsCode={setSelectedFriendsCode} />
          <div >{messagesError}</div>
        </Modal>

        <Modal
          {...propsModal}
          show={isOpenFriendImages}
          onCancel={() => {
            setIsOpenFriendImages(false)
          }}
          onSubmit={(e) => {
            selectedFriendsCode && e.preventDefault()
          }}
          header={selectedUsername}
        >
          <PopFriendImages selectedUserImg={selectedUserImg} selectedEnterImg={selectedEnterImg} loader={loader} />
        </Modal>


        <Modal
          {...propsModalButton}
          show={isOpenEditRemark}
          onCancel={() => setIsOpenEditRemark(false)}
          onSubmit={(e) => {
            e.preventDefault()
            editRemark()
          }}
          header={textDocument.HeaderRemark}
          width="40%"
          right="30%"
        >
          <div className="forget-password">
            <label htmlFor="remark">  {textDocument.ChangingRemark}: </label>
            <input
              value={remark}
              id="remark"
              type="text"
              name="remark"
              onChange={(e) => {
                setRemark(e.target.value);
              }}
            />
            <label>{messagesError}</label>
          </div>
        </Modal>

        <div className="container - EntranceControl " >

          <div className="form_title" style={{ ...isMobile }}>
            {" "}
            <span>{textDocument.EnterancesTracking}</span>{" "}
          </div>
          {/* <!-- End form_title --> */}
          <PreviousOrNext date={moment(date).format("DD/MM/YYYY")} day={day} setDate={setDate} marshal={true} />
          {isLoading &&
            <Box>
              <CircularProgress />
            </Box>}
          {!isLoading && (
            <>
           <div style={{ ...isMobile }}>
  <div className="buttons-entrance-control">
    <div className="pull-opp-dir" style={{ display: 'flex', gap: '10px' }}>
      <button
        type="button"
        className="btn btn-success"
        style={{ minWidth: '120px', height: '40px' }}
        onClick={handleRefresh}
      >
        <span className="refresh-icon"></span> {textDocument.Refresh}
      </button>
      <button
        data-toggle="modal" 
        data-target="#PopUpModal"
        type="button"
        className="btn btn-success"
        style={{ minWidth: '120px', height: '40px' }}
        onClick={() => setIsOpenAddFriendModal(true)}
      >
        {textDocument.AddGolfer}
        <span className="plus-icon"></span>
      </button>
    </div>
  </div>
</div>

              <div style={{ marginBottom: '20px' }}></div>

              <TableContainer
                // component={Paper} 
                sx={TableContainerStyles}
              >
                <Table stickyHeader>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell sx={{ width: '30px', minWidth: '30px' }}>#</StyledTableCell>
                      <StyledTableCell>{textDocument.EnterPicGolfer}</StyledTableCell>
                      <StyledTableCell>{textDocument.Time}</StyledTableCell>
                      <StyledTableCell>{textDocument.Swipe}</StyledTableCell>
                      <StyledTableCell>{textDocument.Source}</StyledTableCell>
                      <StyledTableCell>{textDocument.Name}</StyledTableCell>
                      <StyledTableCell>{textDocument.Accepted}</StyledTableCell>
                      <StyledTableCell>{textDocument.Subscription}</StyledTableCell>
                      <StyledTableCell>{textDocument.HeaderRemark}</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {entriesList.length > 0 && entriesList?.map((entranceItem, i) => (
                      <StyledTableRow key={i}>
                        <TableCell>{entranceItem?.line_nbr}</TableCell>
                        <TableCell sx={CameraCellStyles}>
                          {entranceItem?.line_nbr !== undefined && (
                            <PhotoCameraIcon
                              sx={CameraIconStyles}
                              onClick={() => handleImageClick(
                                entranceItem?.player_latin_name ?
                                  textDocument.language !== "Hebrew" ? entranceItem.player_name : entranceItem.player_latin_name
                                  : entranceItem?.player_name,
                                entranceItem?.customer_code,
                                entranceItem?.sequence_number
                              )}
                            />
                          )}
                        </TableCell>
                        <TableCell>{entranceItem?.start_time}</TableCell>
                        <TableCell>{entranceItem?.swipe_point}</TableCell>
                        <TableCell>{entranceItem?.input_code}</TableCell>
                        <TableCell sx={NameCellStyles}>
                          {entranceItem?.player_latin_name ?
                            textDocument.language !== "Hebrew" ? entranceItem.player_name : entranceItem.player_latin_name
                            : entranceItem?.player_name
                          }
                        </TableCell>
                        <TableCell sx={ApprovedCellStyles}>
                          {    entranceItem?.approved === true ?
                            <CheckCircleIcon color="success" /> :
                            <RemoveCircleOutlineIcon sx={{ color: 'red' }} />
                          }
                        </TableCell>
                        <TableCell>{entranceItem?.user_type}</TableCell>
                        <TableCell
                          sx={RemarkCellStyles}
                          onClick={() => {
                            setIsOpenEditRemark(true);
                            setSeqPlayerRemark(entranceItem?.seq_number);
                          }}
                        >
                      {entranceItem?.remark ? entranceItem?.remark : "---"}
                      </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          <Footer />
        </div>
      </div>
    </>
  );

};

export default Marshal;
