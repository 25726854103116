
import Routing from '../routes/Routing'

import React, { useState, useEffect } from "react";
import { axiosInternalApiGet } from "../network/api/RequestsApi";
import { useDispatch, useSelector } from "react-redux";
import { setEnvDefaults, setTextDoc,setIsMobile } from "../redux/login/LoginAction";
import { GetDetail } from './../Logic/getDetail';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import {isMobile} from 'react-device-detect';

function Home() {

    // const [envDefaults, setEnvDefault] = useState({});
    const [returnGetDetail, setReturnGetDetail] = useState(false);
    const mobileStyle = isMobile ? { marginLeft: '10px', marginRight: '10px' } : {};
    const envDefaultsState = useSelector((state) => state?.envDefaults || null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pathname = window.location.pathname;
    let portEnvDefaults = "/env.defaults.json";
    let portEnv = "/env.json";
    useEffect(() => {
        async function importFilesJson() {
            let object, envJson, resEnvJson = null


            await axiosInternalApiGet(portEnvDefaults)
                .then((response) => {
                    if (response !== "false") {
                        envJson = response;
                        object = envJson;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            await axiosInternalApiGet(portEnv)
                .then((response) => {
                    if (response !== "false") {
                        resEnvJson = response;
                        if (resEnvJson) object = Object.assign(envJson, resEnvJson);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            // setEnvDefault(object);
            if (localStorage.token && pathname !== "/rules" && pathname !== "/registration" && pathname !== "/error" && !pathname?.startsWith('/reset_password/')) {
                const res = await GetDetail(dispatch, object, setReturnGetDetail)
                !res && navigate("/login")

            }

            else if (pathname !== "/rules" && pathname !== "/registration" && pathname !== "/error" && !pathname?.startsWith('/reset_password/')) {

                navigate("/login")
            }
            dispatch(setEnvDefaults(object));
        }
        importFilesJson();
    }, [dispatch, navigate, pathname, portEnv, portEnvDefaults]);

    async function importCurrentLanguage() {
        const language = localStorage.language;
        const direction = localStorage.direction || (language === "rtl" ? "rtl" : "ltr");

        document.body.classList.toggle("ltr", direction === "ltr");
        document.body.classList.toggle("rtl", direction === "rtl");

        let portLanguageDoc = language === "rtl" ? "../../english.json" : "../../hebrew.json";

        await axiosInternalApiGet(portLanguageDoc)
            .then((response) => {
                if (response !== "false") {
                    dispatch(setTextDoc(response));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        function handleStorageChange(event) {
            if (event.key === 'language' || event.key === 'direction') {
                importCurrentLanguage();
            }
        }

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        dispatch(setIsMobile(mobileStyle));
        importCurrentLanguage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //end language

    return (
        <div className="ImportEnv">
            {((returnGetDetail && envDefaultsState?.usersRoute) || envDefaultsState?.usersRoute || (!localStorage?.token && envDefaultsState?.usersRoute)) ? <Routing
            /> :
                <Box >
                    <CircularProgress />
                </Box>
            }
            {/* <Routing envDefaultsState={envDefaultsState} /> */}
        </div>
    )
}
export default Home;