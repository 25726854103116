import {
  ADD_ITEM,
  DELETE_ITEM,
  DIRECTION,
  TEXT_POPUP,
  SET_TEXT_DOC,
  SET_IS_MOBILE,
  SET_ENV_DEFAULTS,
  SET_MY_ORDER_LIST,
  USER_NAME,
  SET_DETAIL,
  FROM_DATE,
  TO_DATE,
  CONNECT,
  BLOCK_DAYS,
  SET_WC_CODE
} from "../constants/index.js";

const initialState = {
  numOfItems: 0,
  activeDir: "rtl",
  textDocument: {},
  envDefaults: {},
  myOrdersList: {},
  textPopup: "",
  userName: "",
  detail: {},
  fromDate: "",
  toDate: "",
  connect: false,
  blockedDays: {},
  wc_code_redux: true,
  isMobile:{}
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM:
      return {
        ...state,
        numOfItems: state.numOfItems + 1,
      };
    case DIRECTION: {
      return {
        ...state,
        activeDir: state.activeDir === "rtl" ? "ltr" : "rtl",
      };
    }
    case TEXT_POPUP: {
      return {
        ...state,
        textPopup: action.payload,
      };
    }
    case SET_WC_CODE: {
      return {
        ...state,
        wc_code_redux: action.payload,
      };
    }
    case BLOCK_DAYS: {
      return {
        ...state,
        blockedDays: action.payload,
      };
    }

    case FROM_DATE: {
      return {
        ...state,
        fromDate: action.payload,
      };
    }

    case TO_DATE: {
      return {
        ...state,
        toDate: action.payload,
      };
    }
    case CONNECT: {
      return {
        ...state,
        connect: action.payload,
      };
    }
    case USER_NAME: {
      return {
        ...state,
        userName: action.payload,
      };
    }
    case DELETE_ITEM:
      return {
        ...state,
        numOfItems: state.numOfItems - 1,
      };
    case SET_TEXT_DOC: {
      return {
        ...state,
        textDocument: action.payload,
      };
    }
    case SET_IS_MOBILE: {
      return {
        ...state,
        isMobile: action.payload,
      };
    }
    case SET_ENV_DEFAULTS: {
      return {
        ...state,
        envDefaults: action.payload,
      };
    }
    case SET_DETAIL: {
      return {
        ...state,
        detail: action.payload,
      };
    }

    case SET_MY_ORDER_LIST: {
      return {
        ...state,
        myOrdersList: action.payload,
      };
    }
    default:
      return state;
  }
};
export default loginReducer;
